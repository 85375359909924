<div class="dropdown" (clickOutside)="isShowCartPopup = false; onClickedCart()">
  <div
    class="cart-icon"
    (click)="isShowCartPopup = !isShowCartPopup; onClickedCart()"
  >
    <img src="assets/img/cart-white.svg" />
    <span class="color-white">{{ "cart._" | translate }}</span>
    <span class="cart-counter" *ngIf="cartCount > 0">{{ cartCount }}</span>
  </div>

  <div class="cart--items" *ngIf="isShowCartPopup">
    <div *ngIf="cartDetails?.count === 0" class="cart-no-items">
      {{ "cart.noItems" | translate }}
    </div>
    <div *ngIf="cartDetails?.count > 0">
      <div class="text--title">{{ "cart.myCart" | translate }}</div>
      <div class="row mb-3">
        <div class="col-auto cart--counter--title mt-3">
          {{ cartDetails?.count }}
          {{
            cartDetails?.count == 1
              ? ("common.item" | translate)
              : ("common.items" | translate)
          }}
        </div>
      </div>
      <div class="divider"></div>
      <app-cart
        [editable]="cartEditable"
        [cartDetails]="cartDetails"
        [cartItems]="cartItems"
        (receivedCartChanges)="onShowCartSummary($event)"
      ></app-cart>

      <div *ngIf="cartStatus === 'In process'">
        <div class="order-in-progress">
          {{ "cart.msgOrderProcessMessage" | translate }}
        </div>

        <div class="progress order-progress">
          <div
            class="progress-bar progress-bar-striped bg-danger"
            role="progressbar"
            style="width: 100%"
            aria-valuenow="100"
            aria-valuemin="0"
            aria-valuemax="100"
          ></div>
        </div>
      </div>
      <div *ngIf="cartStatus !== 'In process'">
        <div class="d-flex flex-column mt-3">
          <div class="d-flex flex-row justify-content-between box--points-2">
            <div class="box--points-2-text">
              {{ "cart.cartTotal" | translate }}
            </div>
            <div class="box--points-2-num">
              {{ cartDetails?.amount_payable | number }}
            </div>
          </div>
        </div>
        <div class="divider"></div>
        <div class="text-danger" *ngIf="showAlertMsgForCartItems">
          {{ showAlertMsgForCartItems }}
        </div>
        <div
          class="box--order mt-3"
          [ngClass]="{
            disabled:
              (cartDetails && cartDetails?.count == 0) || showAlertMsgForCartItems
          }"
          (click)="onCheckout()"
        >
          <span>{{ "common.checkout" | translate }}</span>
        </div>
        <div
          class="mt-3 cursor-pointer text-center"
          [ngClass]="{ disabled: cartDetails && cartDetails?.count == 0 }"
          (click)="gotoMyCart()"
        >
          <span>{{ "cart.viewCart" | translate }}</span>
        </div>
      </div>
    </div>
  </div>
</div>
