<div class="temporarily-section p-4 d-flex align-items-center">
  <div class="temporarily-section-container m-auto">
    <div class="h-title spacing">網站⽬前已關閉以進⾏系統維護</div>
    <div class="h-title my-2">
      The site is currently closed for maintenance.
    </div>
    <img src="/assets/img/for-temporarily.png" alt="" />
    <div class="c-red spacing mt-3">
      ⾝⼼健平台現正暫時停⽌服務，並將於1⽉8⽇上午10時恢復服務。
    </div>
    <div class="p-text spacing mt-2">
      在⾝⼼健平台暫停服務期間，早前於兌換時透過確認電郵發送給你的電⼦禮券代碼、⼆維碼或連結仍可正常使⽤。<br />
      請於系統暫停期間查閱相關確認電郵，以獲取相關的電⼦禮券代碼、⼆維碼或連結。
    </div>
    <div class="p-text spacing mt-2">
      2024年發放的⾝⼼健積分餘額，其使⽤期限可延續⾄2025年的年末。⽽2025年的⾝⼼健積分將會於2025年1⽉8⽇發放。
    </div>
    <div class="c-red mt-3">
      Wellness Platform is currently suspended and will be restored on 8th Jan
      2025 at 10:00am HKT.
    </div>
    <div class="p-text mt-2">
      During the suspension, the e-voucher codes, QR codes or links previously
      sent to you via the confirmation email(s) at the time of your redemption
      made can still be used as normal. Please refer to the confirmation
      email(s) to retrieve the codes or links during this period.
    </div>
    <div class="p-text mt-2">
      Wellness Points granted in 2024 will be carried forward to the year end of
      2025. The 2025 Wellness Points will be granted on 8th Jan 2025.
    </div>
    <hr />
    <div class="contact-details spacing">
      如有疑問，請聯絡MMB 聯絡中⼼ | 服務時間：星期⼀⾄星期五上午 9:00 ⾄下午
      5:30，<br>假⽇除外 電⼦郵件：<a
        href="mailto:MTRFlexibleBenefits@MercerMarshBenefits.com"
        >{{ "MTRFlexibleBenefits@MercerMarshBenefits.com " }}</a
      >
      | 熱線電話：<a href="tel:+85258033606">+852 5803 3606</a
      >（客戶密碼：2540）
    </div>
    <div class="contact-details mt-2">
      For enquiry, please contact the MMB Contact Centre. | Servicing Hours:
      9:00am to 5:30pm, Monday to Friday, excluding public holidays <br />
      Email:
      <a href="mailto:MTRFlexibleBenefits@MercerMarshBenefits.com">{{
        "MTRFlexibleBenefits@MercerMarshBenefits.com"
      }}</a>
      | Hotline: <a href="tel:+85258033606">+852 5803 3606</a> (Client Pin:
      2540)
    </div>
  </div>
</div>
