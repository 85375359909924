<div class="points-img">
  <img class="img-fluid" src="assets/img/home-points.png" />
</div>
<div class="point-container">
  <div class="d-flex justify-content-between">
    <div class="heading d-inline-block mb-1">
      {{ "common.myPoints" | translate }}
    </div>
    <div
      *ngIf="showMoreDetails"
      [routerLink]="['/point-history']"
      [queryParams]="{ point_type: 'all', point_category: 'all' }"
      class="d-xs-block more-btn d-md-none d-inline-block mb-1"
    >
      {{ "common.moreDetails" | translate }} >
    </div>
  </div>
  <div class="row">
    <div class="col-sm-12 col-lg-5 pb-3">
      <div class="box--content">
        <div class="d-flex gap-3">
          <img
            class="img-fluid img--wallet float-start"
            src="assets/img/wallet-1.png"
          />
          <div class="d-flex flex-column">
            <div class="box--spinner-align" [ngClass]="this.appName == 'yara' ? 'cursor-pointer':''" (click)="this.appName == 'yara' ? openPointCalculations():null">
              <span class="points">{{ flexDollars | number }}</span>
              <app-spinner *ngIf="loading"></app-spinner>
            </div>
            <div *ngIf="showPointsOnHome.available" class="box--spinner-align">
              <img
                *ngIf="!syncPoints"
                class="points--reload"
                src="assets/img/reload.png"
                (click)="reload.emit()"
              />
              <div class="points-detail">
                {{ "common.pointsAvailableForRedemption" | translate }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="col-sm-12 col-lg-7">
      <div class="row g-3">
        <div
          *ngIf="showPointsOnHome.earned"
          [class]="!expiryPoint ? 'col-6' : 'col-12 col-md-4'"
          [routerLink]="['/point-history']"
          [queryParams]="{ point_type: 'earned', point_category: 'all' }"
        >
          <div class="d-flex flex-column point-type-container px-2 py-3">
            <div class="point-type-points">
              {{ flexDollarsEarned | number }}
            </div>
            <div class="point-type-details">
              {{ "common.totalPointsEarned" | translate }}
            </div>
          </div>
        </div>
        <div
          *ngIf="showPointsOnHome.redeemed"
          [class]="!expiryPoint ? 'col-6' : 'col-12 col-md-4'"
          [routerLink]="['/point-history']"
          [queryParams]="{ point_type: 'redeemed', point_category: 'all' }"
        >
          <div class="d-flex flex-column point-type-container px-2 py-3">
            <div class="point-type-points">
              {{ flexDollarsRedeemed | number }}
            </div>
            <div class="point-type-details">
              {{ "common.totalPointsRedeemed" | translate }}
            </div>
          </div>
        </div>
        <div
          *ngIf="expiryPoint"
          class="col-12 col-md-4"
          [routerLink]="['/point-history']"
          [queryParams]="{ point_type: 'expiring', point_category: 'all' }"
        >
          <div
            class="d-flex flex-column point-type-container px-2 py-3 background-light"
          >
            <div class="point-type-points">
              {{ pointsExpiringThisMonth.points | number }}
            </div>
            <div class="point-type-details text__expiry-points">
              {{
                "common.totalPointsExpiring"
                  | translate
                    : {
                        year: showYearlyExpiringPoints
                          ? (pointsExpiringThisMonth.expiry_date | date: "yyyy")
                          : (pointsExpiringThisMonth.expiry_date
                            | date: "dd MMM yyyy")
                      }
              }}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="d-flex justify-content-end align-items-center">
    <div class="mt-3 d-md-flex">
      <div
        *ngIf="showMoreDetails"
        class="more-detail d-none d-md-block"
        [routerLink]="['/point-history']"
        [queryParams]="{ point_type: 'all', point_category: 'all' }"
      >
        {{ "common.moreDetails" | translate }}
        <span *ngIf="!lang.isRTL">→</span>
        <span *ngIf="lang.isRTL">←</span>
      </div>
      <div
        *ngIf="syncPoints && showMoreDetails"
        class="more-detail ms-2"
        (click)="syncUserPoints.emit()"
      >
        {{ "common.syncPoints" | translate }}
        <img class="points--reload" src="assets/img/reload.png" />
      </div>
    </div>
  </div>

  <!-- <div class="row g-3 mt-2">
    <div
      *ngIf="expiryPoint"
      class="col-12 col-sm-6 col-md-4"
      [routerLink]="['/point-history']"
      [queryParams]="{ point_type: 'expiring', point_category: 'all' }"
    >
      <div class="d-flex flex-column point-type-container px-2 py-3">
        <div class="point-type-points">
          {{ pointsExpiringThisMonth.points | number }}
        </div>
        <div class="point-type-details text__expiry-points">
          {{
            "common.totalPointsExpiring"
              | translate
                : {
                    year: showYearlyExpiringPoints
                      ? (pointsExpiringThisMonth.expiry_date | date: "yyyy")
                      : (pointsExpiringThisMonth.expiry_date
                        | date: "dd MMM yyyy")
                  }
          }}
        </div>
      </div>
    </div>
    <div
      *ngIf="showPointsOnHome.earned"
      [class]="!expiryPoint ? 'col-6' : 'col-12 col-md-4'"
      [routerLink]="['/point-history']"
      [queryParams]="{ point_type: 'earned', point_category: 'all' }"
    >
      <div class="d-flex flex-column point-type-container px-2 py-3">
        <div class="point-type-points">
          {{ flexDollarsEarned | number }}
        </div>
        <div class="point-type-details">
          {{ "common.totalPointsEarned" | translate }}
        </div>
      </div>
    </div>
    <div
      *ngIf="showPointsOnHome.redeemed"
      [class]="!expiryPoint ? 'col-6' : 'col-12 col-md-4'"
      [routerLink]="['/point-history']"
      [queryParams]="{ point_type: 'redeemed', point_category: 'all' }"
    >
      <div class="d-flex flex-column point-type-container px-2 py-3">
        <div class="point-type-points">
          {{ flexDollarsRedeemed | number }}
        </div>
        <div class="point-type-details">
          {{ "common.totalPointsRedeemed" | translate }}
        </div>
      </div>
    </div>
  </div> -->
</div>
